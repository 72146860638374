<!-- 文件中文名: 订单详情 -->
<template>
  <div class="order-list app-container">
    <p style="
        text-align: left;
        font-size: 30px;
        margin-top: 0;
        margin-bottom: 10px;
      ">
      {{$t('Order.details')}}
    </p>
    <!-- 订单信息 -->
    <table class="table-css">
      <tr>
        <th colspan="2">{{$t('Order.details')}}</th>
      </tr>
      <tr>
        <td>
          <el-form class="orderlists">
<!--            -->
            <el-form-item :label="$t('busi.JPorder.orderNO')">
              {{ viewObj.orderNo}}
            </el-form-item>
            <!--            -->
            <el-form-item :label="$t('create.time')">
              {{ viewObj.createTime}}
            </el-form-item>
            <!--         收货方式   -->
            <el-form-item :label="$t('PoOrder.recType')">
              {{$lt(initDict.rectype,viewObj.recType) }}
            </el-form-item>
            <!--          代办处名字  -->
            <el-form-item  :label="$t('sys.company.code')">
              <div v-if="viewObj.recType === 1"> {{ viewObj.agentNo }}</div>
              <div v-else> {{ viewObj.outCompanycode }}</div>
            </el-form-item>
            <!--           联系人 -->
            <el-form-item :label="$t('biVendor.contacter')">
              {{ viewObj.recName}}
            </el-form-item>
            <!--          地址 -->
            <el-form-item :label="$t('sysUser.address')">
              {{ viewObj.recAddr}}
            </el-form-item>
            <!--      电话     -->
            <el-form-item :label="$t('mi.phone')">
              {{ viewObj.recPhone}}
            </el-form-item>
            <!--      运费     -->
            <el-form-item :label="$t('shipping.cost')">
              <span>{{ viewObj.sendFee}}</span>
              <span v-show="viewObj.recCompanyCode === 'TRA' && viewObj.sendFee > 0">W$</span>
            </el-form-item>
            <!--      促销码     -->
            <el-form-item v-if="viewObj.useQualificationCode" :label="$t('Qualification_code')">
              {{ viewObj.useQualificationCode }}
            </el-form-item>
          </el-form>
        </td>
        <!-- <td>
          <span>{{$t('fiPayAdvice.dealType')+(':')}}</span>&nbsp; 09点27分
          <br />
          <span>{{$t('送货方式')}}</span>&nbsp; 09点28分
        </td> -->
      </tr>
    </table>
    <!-- 黑五促销码 -->
    <div v-show="poProcQualifications.length">
      <table class="table-css">
        <tr>
          <!--资格码-->
          <th colspan="2">{{ $t('Qualification_code') }}</th>
        </tr>
        <tr v-for="ele in poProcQualifications" :key="ele.id">
          <td style="line-height: 18px">
            {{ ele.qualificationCode }}
            <span v-show="ele.qualificationStatus===1" style="color: #55a532;font-size: 18px">
              <i class="el-icon-circle-check"></i>
            </span>
            <span v-show="ele.qualificationStatus===2" style="color: red;font-size: 18px">
              <i class="el-icon-circle-close"></i>
            </span>
            <span v-show="ele.qualificationStatus===1" class="copyBtn" @click="copyData(ele.qualificationCode)">
              <!--复制-->
              {{ $t('button.copy') }}
            </span>
          </td>
        </tr>
      </table>
    </div>
    <!-- 产品信息 -->
    <table class="tableInfo-css">
      <tr>
        <th>{{$t('sr.productName')}}</th>
        <th>{{$t('Product.Code')}}</th>
        <th>{{$t('biBarCode.quantity')}}</th>
        <th>{{$t('bigoods.send.ornot')}}</th>
        <th>{{$t('pd.price')}}</th>
        <th>{{$t('bdSendEfuboBonusDetail.amtCount')}}</th>
      </tr>
      <tr v-for="a in listINfo" :key="a.id">
        <td style="text-align: left">{{ $t(a.name) }}</td>
        <td style="text-align: left">{{ a.goodsCode }}</td>
        <td style="text-align: right">{{ a.quantity }}</td>
        <td style="text-align: right">{{$lt(initDict.isGive,a.isGive) }}</td>
        <td style="text-align: right">
          <div v-if="viewObj.orderType==='21'">
            0
          </div>
          <div v-else>
            <span>{{ a.fv }}&nbsp;Pv</span>
            <br />
            <span>{{ a.f$ }}&nbsp;{{$t('pd.price')}}</span>
            <br v-if="viewObj.orderType==='19'"/>
            <span v-if="viewObj.orderType==='19'">{{ a.pointa0 }} {{$t('bvPointChange.updatePoint')}}</span>
          </div>
        </td>
        <td style="text-align: right">
          <div v-if="viewObj.orderType==='21'">
            0
          </div>
          <div v-else>
            <span>{{ a.fv * a.quantity }}&nbsp;Pv</span>
            <br />
            <span>{{ a.f$ * a.quantity }}&nbsp;{{$t('pd.price')}}</span>
            <br v-if="viewObj.orderType==='19'"/>
            <span v-if="viewObj.orderType==='19'">{{ a.pointa0 * a.quantity }} {{$t('bvPointChange.updatePoint')}}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="5" style="text-align: right">{{$t('bdSendEfuboBonusDetail.amtCount')}}</td>
        <td style="text-align: right">
          <span v-if="viewObj.totalFv">{{ viewObj.totalFv }} &nbsp;Pv</span>
          <br v-if="viewObj.totalFv" />
          <span v-if="viewObj.totalAmount">{{ viewObj.totalAmount }}&nbsp;{{$t('pd.price')}}</span>
          <br v-if="viewObj.totalAmount" />
          <span v-if="viewObj.totalFb">{{ viewObj.totalFb }} {{$t('bdSendEfuboBonus.bonus')}}</span>
          <br v-if="viewObj.orderType==='19'"/>
          <span v-if="viewObj.orderType==='19'&&viewObj.totalH0">{{ viewObj.totalH0 }} {{$t('bvPointChange.updatePoint')}}</span>
        </td>
      </tr>
    </table>
    <el-button type="warning" plain style="float: right; margin: 10px 0" @click="btnContinue">{{$t('btn.continue')}}</el-button>
  </div>
</template>
<script>
  import { orderView } from "@/api/rus/api"
  export default {
    name: "OrderListView",
    data () {
      return {
        viewObj: {},
        poProcQualifications: [],
        listINfo: [],
        initDict: {
          status: 'po.order.status',
          payment: 'po.order.payment.cn',
          rectype: 'poorder.rectype',
          isGive:'po.order.isgive'
        }
      }
    },
    created () {
      this.goodsId = this.$route.query.id
      if (this.goodsId) {
        this.getList(this.goodsId)
      } else {
        this.$router.go(-1)
      }
    },
    methods: {
      getList (id) {
        orderView({ 'id': id }).then(res => {
          this.viewObj = res.data
          this.poProcQualifications = res.data.poProcQualifications
          this.listINfo = res.data.poOrderDetails
        })
      },
      btnContinue () {
        this.$router.push({ path: "/order-list" })
      },
      copyData(val) {
        // navigator.clipboard.writeText(val).then(()=>{
        //   this.$message.success('copy success')
        // })
        const textArea = document.createElement('textArea')
        textArea.value = val
        textArea.style.width = 0
        textArea.style.position = 'fixed'
        textArea.style.left = '-999px'
        textArea.style.top = '10px'
        textArea.setAttribute('readonly', 'readonly')
        document.body.appendChild(textArea)
        textArea.select()
        document.execCommand('copy')
        this.$message.success('copy success')
        document.body.removeChild(textArea)
      },
    },
  };
</script>

<style scoped lang="scss">
.copyBtn {
  color: #0000FF;
  cursor: pointer;
}
.orderlists{
  ::v-deep .el-form-item{
  margin-bottom: 0;
  }
}
  .table-css {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    tr {
      th {
        padding: 5px;
        width: 150px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        border: 1px solid #eeeeee;
        text-align: left;
        background: #dddddd;
      }
      td {
        padding: 5px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        border: 1px solid #dddddd;
        text-align: left;
        background: #ffffff;
        span {
          font-weight: 800;
        }
      }
    }
  }
  .tableInfo-css {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    tr {
      th {
        padding: 5px;
        width: 150px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        border: 1px solid #eeeeee;
        text-align: center;
        background: #dddddd;
      }
      td {
        padding: 5px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        border: 1px solid #dddddd;
        background: #ffffff;
        span {
          font-weight: 800;
        }
      }
    }
  }
</style>
